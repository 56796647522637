import React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../components/blog.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Commentsimg from '../images/comments.svg';
import ProfileImg from '../images/profile.svg';
import BlogImg from '../images/propmesignsdeal.png';
import Facebook from '../images/facebookBlue.png';
import Twitter from '../images/twitterBlue.png';
import Linkedin from '../images/linkedinBlue.png';
import MailBlue from '../images/mailBlue.png';
import CardsRowImg1 from '../images/cardsRowImg1.png';
import CardsRowImg2 from '../images/cardsRowImg2.png';
import CardsRowImg3 from '../images/cardsRowImg3.png';
import { FacebookProvider, Comments } from 'react-facebook';

class propmesignsdeal extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  // componentDidMount() {
  //   window.fbAsyncInit = function() {
  //   window.FB.init({
  //     appId: "691895914788460",
  //     autoLogAppEvents : true,
  //     xfbml            : true,
  //     version          : 'v3.0'
  //    });
  //   };
  
  //   // Load the SDK asynchronously
  //   (function(d, s, id) {
  //    var js,
  //      fjs = d.getElementsByTagName(s)[0];
  //    if (d.getElementById(id)) return;
  //    js = d.createElement(s);
  //    js.id = id;
  //    js.src = "//connect.facebook.net/en_US/sdk.js";
  //    fjs.parentNode.insertBefore(js, fjs);
  //  })(document, "script", "facebook-jssdk");
  // }  
  
  // componentDidUpdate() {
  //    window.FB.XFBML.parse();
  //  }
  render(){
    return(
      <Layout>
        <div id="fb-root"></div>
        <div className={styles.landingSection}>
          <div className={styles.landingSectionSubDiv}>
            <text className={styles.blogHeader}>PropMe Signs Exclusive Deal with West Virginia Bar and Restaurant Association</text>
            <div className="row" id={styles.subtitle}>
              <div className={styles.profileCol2}>
                <img className={styles.ProfileImg} src={ProfileImg} alt="landingimg" />
                <text className={styles.profileTest}>By PR Newswire</text>
                <p>10 May, 2019</p>
              </div>
              <div className={styles.profileCol2} id={styles.profilecolId}>
                <img className={styles.ProfileImg} src={Commentsimg} alt="landingimg" />
                <text className={styles.profileTest}>0 Comments</text>
              </div>
            </div>
            <text className={styles.blogDesc}>
              <p>Partnership to provide Bar and Restaurant patrons throughout the state with unique and exclusive prop-betting opportunities on sports, entertainment, and political events.</p>
            </text>
            <div className={styles.blogimage}>
              <img className={styles.signImg}src={BlogImg} alt="landingimg" />
            </div>
            <text className={styles.blogDesc}>
              <p>PR Newswire</p>
              <p>MCMECHEN, W.V., May 9, 2019 /PRNewswire/ -- PropMe, LLC (PropMe) and the WV Bar and Restaurant Association (WVRBA) announced today a new multi-year partnership that will focus on making PropMe the exclusive official sports wagering partner at up-to 3,200 eating and drinking locations throughout the state.</p>
              <p>Read the full article below:  </p>
              <text><a href="https://seekingalpha.com/pr/17506699-propme-signs-exclusive-deal-west-virginia-bar-restaurant-association" target="_blank">https://seekingalpha.com/pr/17506699-propme-signs-exclusive-deal-west-virginia-bar-restaurant-association</a></text>
            </text>
            <div className={styles.oldPostsLink}>
            <a className={styles.servicelinks} href="/braden-coming-to-mcmechen"><text>{'<'}Older Post</text></a>
            <a className={styles.servicelinks} href="/jeffrey-v-kessler-joins-progame-sports"><text>Newer Post{'>'}</text></a>
            </div>
            <div className={styles.BlogsocialLinksDiv}>
              <img className={styles.social} src={Facebook} alt="landingimg" />
              <img className={styles.social} src={Twitter} alt="landingimg" />
              <img className={styles.social} src={Linkedin} alt="landingimg" />
              <img className={styles.social} src={MailBlue} alt="landingimg" />
            </div>
    
            {/* <div class="fb-comments" data-href="https://friendly-curran-11b640.netlify.app/blog/" data-width="1000" data-numposts="5"></div> */}
            <iframe name="f297624ad60196" width="500px" height="100px" data-testid="fb:comments Facebook Social Plugin" title="fb:comments Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.0/plugins/comments.php?app_id=691895914788460&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df247eedd96682d4%26domain%3Dfriendly-curran-11b640.netlify.app%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ffriendly-curran-11b640.netlify.app%252Ffe72a2fa7cef6c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=714&amp;height=100&amp;href=https%3A%2F%2Fwww.facebook.com%2Fblam%2F&amp;locale=en_US&amp;numposts=6&amp;sdk=joey&amp;version=v2.0&amp;width=500" style={{border: 'none', visibility: 'visible', width: '500px', height: '458px'}} class=""></iframe>
            <div className={styles.featuredNewsDiv}>
              <text>Featured News</text>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg1} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>PropMe’s Peer-to-Peer Marketplace</text>
                    </div>
                    <text className={styles.partnerDesc}><p>Overview. Sports wagering in America received its wings on May 14, 2018 when the United States Supreme… Court ruled that the Professional and Amateur Sports Protection Act (PASPA), the 1992 federal law that hadPeer-to-Peer Marketplace</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                    {/* <a className={styles.servicelinks} href="/lmschain">LMSCHAIN</a> </p>
                    <p>SAP Blog: <a className={styles.servicelinks} href="#">here</a> */}
                    <a className={styles.servicelinks} href="/propmes-peer-to-peer-marketplace"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg2} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>Braden Wins the Snowball</text>
                    </div>
                    <text className={styles.partnerDesc}><p>It was the most improbable finish of his career. It was wild and it was staggering, several times over. But if you’ve paid close attention, you could’ve seen this coming, because it was also methodical and surgical. It was Travis Braden showing how it’s done.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                    <a className={styles.servicelinks} href="/braden-wins-the-snowball"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg3} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>Braden Helps Promote Virtual Racing</text>
                    </div>
                    <text className={styles.partnerDesc}><p>MCMECHEN — Pro race car driver and Wheeling native Travis Braden grew up wishing that video game technology... would be more realistic — especially when it came to racing. That time has come.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                    <a className={styles.servicelinks} href="/braden-helps-promote-virtual-racing"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Layout>
    )
  }
}

export default propmesignsdeal